import FormRules from "@/forms/FormRules";
import store from "../store";
import PaymentsService from "../services/PaymentsService";
import LogoSbp from "@/assets/icons/logo_sbp.svg";

import moment from "moment";
import { format, parseISO } from "date-fns";
moment.locale("ru");

const PAYMENT_TYPES = {
  insurance: "insurance",
  epd: "epd",
};

export default class PaymentForm extends FormRules {
  valid = true;
  name = "PaymentForm";

  payload = {
    lsNumber: store.getters["user/activeBindingLs"],
    sum: this.getSum(),
    email: this.getUserEmail(),
    dayRecurrent: 1,
    sumRecurrent: 5000,
    endRecurrent: this.getDate(),
    selectedCard: null,
    debt: 0,
    gp: 0,
  };

  fields = {
    lsNumber: {
      label: "",
      type: "text",
      rules: [this.required("Необходимо заполнить это поле")],
      title: "Лицевой счет",
      required: true,
      class: "mr-md-2",
      disabled: true,
    },
    sum: {
      label: "",
      type: "text",
      rules: [this.required("Необходимо заполнить это поле")],
      title: "Сумма платежа",
      required: true,
      icon: "mdi-currency-rub",
      class: "mr-md-2 rub-input",
      useLoading: true,
    },
    email: {
      label: "Email",
      type: "email",
      rules: [this.validEmail()],
      title: "E-mail для получения чека",
      required: false,
    },
  };

  // автоплатежи
  recurrent = false;

  fieldsRecurrent = {
    dayRecurrent: {
      label: "",
      type: "select",
      rules: [this.required("Необходимо заполнить это поле")],
      title: "День месяца",
      required: this.recurrent && true,
      class: "mr-md-2",
      items: Array.from({ length: 15 }, (_, i) => i + 1),
      itemChangeHandler: (item) => (this.payload.dayRecurrent = item),
    },
    sumRecurrent: {
      label: "",
      type: "text",
      rules: [this.validPaymentSum()],
      title: "Максимальная сумма",
      required: this.recurrent && true,
      icon: "mdi-currency-rub",
      class: "mr-md-2 rub-input",
    },
    endRecurrent: {
      label: "",
      type: "date",
      rules: [this.required("Необходимо заполнить это поле")],
      title: "Дата прекращения",
      required: this.recurrent && true,
    },
  };

  buttons = [
    {
      label: "Оплатить",
      type: "submit",
      // block: true,
      loading: false,
      checkOuter: false,
      action: async () => {
        const button = this.buttons.find(
          (button) => button.label === "Оплатить"
        );
        button.loading = true;
        try {
          const link = await PaymentsService.getPaymentLink(
            this.payload.sum,
            this.getPaymentType(),
            this.payload.email,
            false, // СБП
            "", // источник
            this.payload.debt,
            this.payload.gp,
            this.recurrent,
            this.payload.dayRecurrent,
            this.payload.sumRecurrent,
            this.payload.endRecurrent,
            this.payload.selectedCard && this.payload.selectedCard.id
          );

          if (link.url) {
            window.location.href = link.url;
          }
        } catch (e) {
          console.log(e.message);
        }
        button.loading = false;
      },
    },
    {
      label: "Оплатить СБП",
      type: "submit",
      // block: true,
      loading: false,
      checkOuter: true,
      color: "#1d1346",
      logo: LogoSbp,
      class: "sbp-text-color",
      action: async () => {
        const button = this.buttons.find(
          (button) => button.label === "Оплатить СБП"
        );
        button.loading = true;
        try {
          const link = await PaymentsService.getPaymentLink(
            this.payload.sum,
            this.getPaymentType(),
            this.payload.email,
            true, // СБП
            "СБП", // источник
            this.payload.debt,
            this.payload.gp,
          );

          if (link.url) {
            window.location.href = link.url;
          }
        } catch (e) {
          console.log(e.message);
        }

        button.loading = false;
      },
    },
  ];

  getSum() {
    if (this.getPaymentType() === PAYMENT_TYPES.insurance) {
      return store.getters["account/totalWithInsurance"];
    }

    return store.getters["account/total"];
  }

  getPaymentType() {
    if (window.location.search.includes("withInsurance=1")) {
      return PAYMENT_TYPES.insurance;
    }

    return PAYMENT_TYPES.epd;
  }

  getUserEmail() {
    return store.state.user.login;
  }

  getDate() {
    let d = new Date();
    return format(
      parseISO(
        new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()).toISOString()
      ),
      "yyyy-MM-dd"
    );
  }
}

<template>
  <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        class="date-input"
        :value="formattedDate"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="value = null"
        outlined
        dense
        hide-details
        append-icon="mdi-calendar"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="mutableDate"
      @change="valueChanged"
      locale="ru"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";
moment.locale("ru");
import { format, parseISO } from "date-fns";

export default {
  name: "SimpleDatePicker",

  props: {
    date: {
      type: String,
      default: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
    },
  },

  data() {
    return {
      menu: false,
      mutableDate: this.date,
    };
  },

  methods: {
    valueChanged() {
      this.menu = false;
      this.$emit("update:date", this.mutableDate);
    },
  },

  computed: {
    formattedDate() {
      return this.mutableDate ? moment(this.mutableDate).format("L") : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.date-input {
  width: 150px;
  margin-right: 1em;
}

.v-card__title {
  padding-bottom: 0;
}

@media (max-width: 600px) {
  .date-input {
    margin-bottom: 1em;
  }
}

@media (max-width: 414px) {
  .date-input {
    width: 100%;
    margin-right: 0;
  }
}
</style>

<template>
  <page-layout>
    <v-col cols="12">
      <simple-card>
        <template v-slot:title-left>Оплата</template>
        <template v-slot:content>
          <div>
            <payment-form></payment-form>
            <payment-additional-info></payment-additional-info>
          </div>
        </template>
      </simple-card>
    </v-col>
  </page-layout>
</template>

<script>
import SimpleCard from "@/components/cards/SimpleCard";
import PaymentForm from "@/components/forms/PaymentForm";
import PageLayout from "../../components/Layouts/PageLayout";
import PaymentAdditionalInfo from "./PaymentAdditionalInfo";

export default {
  name: "Payment",
  components: { SimpleCard, PaymentForm, PageLayout, PaymentAdditionalInfo },
};
</script>

<style scoped></style>
